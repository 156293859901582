import React from "react";
import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";

const containerStyle = {
  width: '100%',
  height: '600px',
}

const center = {
  lat: -15.4190643,
  lng: 145.0878744,
}

function Map() {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: 'AIzaSyArReDEGW3k3kjE2RAg86ICkHPCEwPAl6Q',
  })

  const [, setMap] = React.useState(null)

  const onLoad = React.useCallback(function callback(map) {
    // This is just an example of getting and using the map instance!!! don't just blindly copy!
    const bounds = new window.google.maps.LatLngBounds(center)
    map.fitBounds(bounds)
    setMap(map)
  }, [setMap])

  const onUnmount = React.useCallback(function callback() {
    setMap(null)
  }, [setMap])

  return isLoaded ? (
    <div className="container map">
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={center}
      zoom={10}
      onLoad={onLoad}
      onUnmount={onUnmount}
      mapTypeId="hybrid"
    >
      {/* Child components, such as markers, info windows, etc. */}
      <Marker
        position={center}
      />
      <></>
    </GoogleMap>
    </div>
  ) : (
    <></>
  );
  }
  export default Map;
  