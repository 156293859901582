function Header() {
  return (
    <header>
      <img src="./img/yai-yai.jfif" alt="Yai Yai"></img>
      <h1>SEASONAL PRODUCE</h1>
      <div className="row">
      </div>
      <div className="headerbg"></div>
    </header>
  );
}
export default Header;
