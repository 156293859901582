import About from '../components/About';
import Produce from './Produce';
import Map from './Map';

function Main() {
  return (
    <>
      <main>
        <Produce />
        <About />
        <Map />
      </main>
    </>
  );
}
export default Main;
